import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { APIProvider } from './contexts/APIContext';
import { ApplicantUtilsProvider } from './contexts/ApplicantUtilsContext';
import { AuthProvider } from './contexts/AuthContext';
import { CountryBasedValuesProvider } from './contexts/CountryBasedValuesContext';
import { DialmakerDesktopCacheProvider } from './contexts/dialmakerDesktop/DialmakerDesktopCacheContext';
import { DialmakerDesktopUtilsProvider } from './contexts/dialmakerDesktop/DialmakerDesktopUtilsContext';
import { IdleHelperProvider } from './contexts/IdleHelperContext';
import { IframeUtilsProvider } from './contexts/IframeUtilsContext';
import { SearchProvider } from './contexts/SearchContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { UtilsProvider } from './contexts/Utils';
import { ValidatorContextProvider } from './contexts/ValidatorContext';
// eslint-disable-next-line import/first

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0, // Good idea to keep it as 1.0 during the first month in production
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  // Redux recommendation to se to 10: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
  normalizeDepth: 10,
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <CookiesProvider>
              <DialmakerDesktopUtilsProvider>
                <AuthProvider>
                  <SettingsProvider>
                    <UtilsProvider>
                      <APIProvider>
                        <ValidatorContextProvider>
                          <DialmakerDesktopCacheProvider>
                            <SearchProvider>
                              <IframeUtilsProvider>
                                <CountryBasedValuesProvider>
                                  <ApplicantUtilsProvider>
                                    <IdleHelperProvider>
                                      <App />
                                    </IdleHelperProvider>
                                  </ApplicantUtilsProvider>
                                </CountryBasedValuesProvider>
                              </IframeUtilsProvider>
                            </SearchProvider>
                          </DialmakerDesktopCacheProvider>
                        </ValidatorContextProvider>
                      </APIProvider>
                    </UtilsProvider>
                  </SettingsProvider>
                </AuthProvider>
              </DialmakerDesktopUtilsProvider>
            </CookiesProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);
